import { Component, HostListener } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle, IdleExpiry, LocalStorageExpiry } from '@ng-idle/core';
import { environment } from '../environments/environment';
import ConfigUtils from './utils/ConfigUtils';

@Component({
  providers: [LocalStorageExpiry, { provide: IdleExpiry, useExisting: LocalStorageExpiry }, Idle],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;

  constructor(private idleCheck: Idle) {
    this.SetEnvironmentVars();
    this.SetupIdleCheck();
  }

  private SetEnvironmentVars() {
    // SITE KEY: SET WHEN NOT IN PROD OR DEV
    if (!ConfigUtils.isProdEnv() && !ConfigUtils.isDevEnv()) {
      environment.SiteKey = '6LfaE3kUAAAAAFPvp8A6irSVIcx1iiY8Ox6W_auZ';
    }
  }

  private SetupIdleCheck() {
    // CLEAR IDLE FIRST
    localStorage.removeItem('ng2Idle.idleCheck.expiry');
    localStorage.removeItem('ng2Idle.idleCheck.idling');


    this.idleCheck.setIdleName('idleCheck');

    // 30 MINUTE IDLE TIMER
    this.idleCheck.setIdle(60 * 30);

    // 30 SECOND COUNTDOWN.
    this.idleCheck.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idleCheck.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idleCheck.onIdleEnd.subscribe(() => {
      if (sessionStorage.length < 1) {
        return;
      }
      this.idleState = 'No longer idle.';
    });
    this.idleCheck.onTimeout.subscribe(() => {
      if (sessionStorage.length < 1) {
        return;
      }
      this.idleState = 'Timed out!';
      this.timedOut = true;
      sessionStorage.clear();
      location.reload();
    });
    this.idleCheck.onIdleStart.subscribe(() => {
      if (sessionStorage.length < 1) {
        return;
      }
      this.idleState = `You've gone idle!`;
    });
    this.idleCheck.onTimeoutWarning.subscribe((countdown) => {
      if (sessionStorage.length < 1) {
        return;
      }
      this.idleState = `You will time out in ${countdown} seconds!`;
    });

    this.idleCheck.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
