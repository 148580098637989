import { DatePipe } from '@angular/common';
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { VersionCheckService } from '../../services/version-check.service';
import { Alert, AlertType } from '../../shared/dismissible-alerts/classes';
import { ModalUtils } from '../../utils/ModalUtils';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html'
})
export class SiteLayoutComponent implements OnInit {

  //#region Variables
  modal: ModalUtils = null;
  _pageTitle: string;
  _header = true;
  _downloadName: string;
  _safeUrl: any;
  _alerts: Alert[] = [];
  _maintenanceWindow: {start: Date, end: Date} = null;
  //#endregion

  constructor(
    private spinner: NgxUiLoaderService,
    public router: Router,
    public route: ActivatedRoute,
    private svc: AuthService,
    private vsvc: VersionCheckService,
  ) {
    this.modal = new ModalUtils(this);

    this.svc.Get()
    .subscribe(
      response => {
        this._maintenanceWindow = {start: null, end: null};
        this._maintenanceWindow.start = response.Item1;
        this._maintenanceWindow.end = response.Item2;

        if (this._maintenanceWindow.start && this._maintenanceWindow.end) {
          if (this._maintenanceWindow.start.toString() >= new Date().toISOString()) {
            const mdp = new MaintenanceDatePipe();
            this.ShowWarningMessage(`Heads up! DealSimple™ will be undergoing maintenance and will be unavailable from
            <b>${mdp.transform(this._maintenanceWindow.start)}</b> until <b>${mdp.transform(this._maintenanceWindow.end)}</b>`);
          }
        }
      }
    );
  }

  ngOnInit() {
     // DEFAULTED TO CHECK EVERY 2 MINS
     this.vsvc.initVersionCheck();
  }

  SetIncludeHeader(value: boolean) {
    this._header = value;
  }

  ShowErrorMessage(message: string, seconds = 5): void {
    this._alerts.push(new Alert(AlertType.DANGER, message));
  }

  ShowSuccessMessage(message: string, seconds = 15): void {
    this._alerts.push(new Alert(AlertType.SUCCESS, message));
  }

  ShowWarningMessage(message: string): void {
    this._alerts.push(new Alert(AlertType.WARNING, message));
  }

  HideSpinner() {
    this.spinner.stop();
  }

  ShowSpinner() {
    this.spinner.start();
  }
}


@Pipe({
  name: 'maintenanceDate'
})
export class MaintenanceDatePipe implements PipeTransform {
  transform(date: Date, format = 'MMMM d h:mm a') {
    return new DatePipe('en-US').transform(new Date(date.toString() + 'Z'), format);
  }
}
