import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subscription, User } from '../classes/classes';
import ConfigUtils from '../utils/ConfigUtils';

//#region CurrentUser
export class CurrentUser {
  Role: RoleType;
  Token: string;
  RefreshToken: string;
  SignerId: string;
  SignerName: string;
  User: User;
  Subscription: Subscription;
  MaintenanceWindow: any;
}
//#endregion
//#region RoleType
export enum RoleType {
  Invalid = 0,
  Agent = 1,
  Conveyancer = 2,
  ListingAgent = 4,
  Guest = 8,
  Signer = 1024,
  EnvelopeSigner = 2048,
  Admin = 1073741824
}

export class RoleUtils {
  // Adds a role to the given role
  public static AddRole(value: RoleType, toAdd: RoleType): RoleType {
    return value | toAdd;
  }

  // Removes a role from the given role
  public static RemoveRole(value: RoleType, toRemove: RoleType): RoleType {
    return RoleUtils.HasRole(value, toRemove) ? value ^ toRemove : value;
  }

  // Checks whether or not a role exists on the given role.
  public static HasRole(value: RoleType, has: RoleType): boolean {
    return (value & has) == has;
  }

  // This function ensures that there is some overlap between the two roles given.
  // So if you pass in someone that has Agent and ListingAgent, and then Signer | ListingAgent,
  // It will return true, but pass in Signer | EnvelopeSigner and it will return false.
  public static HasSome(value: RoleType, has: RoleType): boolean {
    return (value & has) != RoleType.Invalid;
  }

  public static ToString(value: RoleType): string {
    const roles = Object.keys(RoleType)
      .filter((x) => isNaN(Number(x)) === false)
      .map(key => RoleType[key]);

    let role: string[] = [];

    roles.forEach(x => {
      if (RoleUtils.HasRole(value, x)) {
        role.push(RoleType[x].replace(/([a-z])([A-Z])/g, '$1 $2'));
      }
    });

    return role.join();
  }
}
//#endregion

@Injectable()
export class AuthService {

  private _baseUrl: string;
  private _currentUserSubject: BehaviorSubject<CurrentUser>;
  private _tokenUrl: string;

  constructor(private http: HttpClient) {
    this._baseUrl = ConfigUtils.GetBaseApiUrl() + '/auth';
    this._currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(sessionStorage.getItem('user')));
    this._tokenUrl = ConfigUtils.GetBaseUrl() + '/token';
  }

  public get CurrentUser(): CurrentUser {
    return this._currentUserSubject.value;
  }

  public Login(username: string, password: string) {

    return this.http.post<any>(this._tokenUrl,
      `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password`)
      .pipe(map(data => {
        const user = new CurrentUser();

        if (data && data.access_token) {
          user.Token = data.access_token;
          user.RefreshToken = data.refresh_token;
          user.Role = +data.Role;
          user.User = JSON.parse(data.User);
          user.Subscription = JSON.parse(data.Subscription);
          user.MaintenanceWindow = JSON.parse(data.MaintenanceWindow);

          sessionStorage.setItem('user', JSON.stringify(user));
          this._currentUserSubject.next(user);
        }

        return user;
      }));
  }

  public LoginOther(encryptedId: string): any {
    return this.http.post<any>(this._tokenUrl,
      `username=other&password=other&client_id=${encodeURIComponent(encryptedId)}&grant_type=password`)
      .pipe(map(data => {
        const user = new CurrentUser();

        if (data && data.access_token) {
          user.Token = data.access_token;
          user.RefreshToken = data.refresh_token;
          user.Role = +data.Role;
          user.SignerId = data?.SignerId;
          user.SignerName = data?.SignerName;
          user.MaintenanceWindow = JSON.parse(data?.MaintenanceWindow);

          if (!RoleUtils.HasRole(data.Role, RoleType.Signer) && !RoleUtils.HasRole(data.Role, RoleType.EnvelopeSigner)) {
            user.User = JSON.parse(data.User);
          }

          sessionStorage.setItem('user', JSON.stringify(user));
          this._currentUserSubject.next(user);
        }

        return user;
      }));
  }

  public Logout() {
    sessionStorage.removeItem('user');
    this._currentUserSubject.next(null);
  }

  public RefreshToken(): any {

    return this.http.post<any>(this._tokenUrl,
      `grant_type=refresh_token&refresh_token=${this.CurrentUser.RefreshToken}`)
      .pipe(map(data => {

        if (data && data.access_token) {
          this.CurrentUser.Token = data.access_token;
          this.CurrentUser.RefreshToken = data.refresh_token;
          this.CurrentUser.Role = +data.Role;
          this.CurrentUser.User = JSON.parse(data.User);
          this.CurrentUser.MaintenanceWindow = JSON.parse(data?.MaintenanceWindow);

          sessionStorage.setItem('user', JSON.stringify(this.CurrentUser));
          this._currentUserSubject.next(this.CurrentUser);
        }

        return this.CurrentUser;
      }));
  }

  public Post(data: string): Observable<any> {
    return this.http.post<any>(this._baseUrl, JSON.stringify(data));
  }

  public Get(): Observable<any> {
    return this.http.get<any>(this._baseUrl);
  }
}
